import React from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { BaseUrl } from "./api";
import Cookies from "universal-cookie";
import { withTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";

import "swiper/css/bundle";

const cookies = new Cookies();

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

class Post extends React.Component {
  constructor() {
    super();
    this.state = {
      post: [],
    };
    this._isMounted = false;

    if (cookies.get("Language")) {
      this.lang = cookies.get("Language");
    } else {
      this.lang = "uz";
    }
    if (this.lang === "uz") {
      this.name = "nameUz";
      this.title = "titleUz";
      this.text = "textUz";
    } else {
      this.name = "nameRu";
      this.title = "titleRu";
      this.text = "textRu";
    }
  }

  componentDidMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    this._isMounted = true;
    let { id } = this.props.params;
    axios.get(BaseUrl + `posts/${id}/`).then((res) => {
      let post = res.data;

      this.data = post.createdAt.slice(0, 10);
      this._isMounted && this.setState({ post });
      document.title = this.state.post[this.title]
        ? this.state.post[this.title]
        : "SAG";
      console.log(this.state.post);
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const post = this.state.post;

    return (
      <div className="main py-3">
        <div className="container">
          <div className="blog-image">
            <img src={post.image} alt={post.seoTitleUz} />
          </div>
          <div className="blog-date">
            <i className="fa-regular fa-calendar"></i>
            <p>{this.data}</p>
          </div>
          <h1 className="text-title">{post[this.title]}</h1>
          {post.textUz && ReactHtmlParser(post[this.text])}
        </div>
      </div>
    );
  }
}

export default withParams(withTranslation()(Post));
